/* src/inc/regUser.js */
import React, { Component } from "react";
import Head from "./header";

import axios from "axios";

class RegUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userNo: "",
      userName: "",
      userPhone: "",
      userEMail: "",
      userPassword: "",
      userTaxinfo: "",
      userVehicleNo: "",
      userApproved: 0,
      isLoading: true,
      error: null,
    };
  }

  _addData = async (e) => {
    const userNo = this.state.userNo.trim();
    const userName = this.state.userName.trim();
    const userPhone = this.state.userPhone.trim();
    const userEMail = this.state.userEMail.trim();
    const userPassword = this.state.userPassword.trim();
    const userTaxinfo = this.state.userTaxinfo.trim();
    const userVehicleNo = this.state.userVehicleNo.trim();
    const userApproved = this.state.userApproved;

    if (userNo === "") {
      return alert("차량 호수 입력해주세요.");
    } else if (userName === "") {
      return alert("기사 성함을 입력해주세요.");
    } else if (userPhone === "") {
      return alert("전화번호 입력해주세요.");
    } else if (userEMail === "") {
      return alert("이메일을 입력해주세요.");
    } else if (userPassword === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const obj = {
      userNo: userNo,
      userName: userName,
      userPhone: userPhone,
      userEMail: userEMail,
      userPassword: userPassword,
      userTaxinfo: userTaxinfo,
      userVehicleNo: userVehicleNo,
      userApproved: userApproved,
    };

    console.log(obj);

    const res = await axios("/add/driver", {
      method: "POST",
      data: obj,
      headers: new Headers(),
    });

    if (res.data) {
      //console.log(res.data.msg);
      if (res.data) {
        alert("새로운 기사님을 등록 했습니다.");
        return window.location.reload();
      } else {
        alert("이메일 중복, 차량 호수 확인");
      }
    }
  };

  _changeuserNO = function () {
    const userNo_v = document.getElementsByName("userNo")[0].value;

    this.setState({
      userNo: userNo_v,
    });
  };

  _changeuserName = function () {
    const userName_v = document.getElementsByName("userName")[0].value;

    this.setState({
      userName: userName_v,
    });
  };

  _changeuserPhone = function () {
    const userPhone_v = document.getElementsByName("userPhone")[0].value;

    this.setState({
      userPhone: userPhone_v,
    });
  };

  _changeuserEMail = function () {
    const userEMail_v = document.getElementsByName("userEMail")[0].value;

    this.setState({
      userEMail: userEMail_v,
    });
  };

  _changeuserPassword = function () {
    const userPassword_v = document.getElementsByName("userPassword")[0].value;

    this.setState({
      userPassword: userPassword_v,
    });
  };

  _changeuserTaxinfo = function () {
    const userTaxinfo_v = document.getElementsByName("userTaxinfo")[0].value;

    this.setState({
      userTaxinfo: userTaxinfo_v,
    });
  };

  _changeuserVehicleNo = function () {
    const userVehicleNo_v =
      document.getElementsByName("userVehicleNo")[0].value;

    this.setState({
      userVehicleNo: userVehicleNo_v,
    });
  };

  _changeuserApproved = function () {
    const userApproved_v = document.getElementsByName("userApproved")[0].value;

    this.setState({
      userApproved: userApproved_v,
    });
  };

  movehome = function (e) {
    return window.location.replace("/adminmenu");
  };

  render() {
    return (
      <div>
        <Head />
        <h2>기사 등록</h2>
        <div className="form">
          <table>
            <caption>*항목은 반드시 입력 하세요</caption>
            <thead>
              <tr>
                <th scope="col" colspan="2" align="center">
                  기사 정보
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <td>기사번호</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userNo"
                    placeholder="호"
                    onChange={() => this._changeuserNO()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>기사성함</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userName"
                    placeholder="이름"
                    onChange={() => this._changeuserName()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Phone No</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userPhone"
                    placeholder="Phone No"
                    onChange={() => this._changeuserPhone()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>E-Mail</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userEMail"
                    placeholder="E-Mail"
                    onChange={() => this._changeuserEMail()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Password</td>
                <td>
                  <input
                    class="custom-input"
                    type="password"
                    name="userPassword"
                    placeholder="Password"
                    onChange={() => this._changeuserPassword()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Tax Info</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userTaxinfo"
                    placeholder="SSN or EIN"
                    onChange={() => this._changeuserTaxinfo()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Vehicle No</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userVehicleNo"
                    placeholder="Vehicle No"
                    onChange={() => this._changeuserVehicleNo()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>ACTIVE</td>
                <td>
                  <div class="custom-select">
                    <select
                      name="userApproved"
                      value="0"
                      onChange={() => this._changeuserApproved()}
                    >
                      <option key="Y" value="0">
                        ACTIVE
                      </option>
                      <option key="N" value="1">
                        INACTIVE
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th scope="col" colspan="2" align="center"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <td>
                  <input
                    className="mbtn"
                    type="submit"
                    value="닫기"
                    onClick={this.movehome}
                  />
                </td>
                <td>
                  <input
                    className="btn"
                    type="submit"
                    value="저장"
                    onClick={this._addData}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RegUser;
