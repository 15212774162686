/* src/inc/lstFind.js */
import React, { Component } from "react";
/* Bootstrap */

//import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import * as common from "./functions";
import withNavigate from "./withNavigate";
import Head from "./header";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
import "moment/locale/et";
import "@fontsource/roboto/300.css";

// MUI COM
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TextField from "@mui/material/TextField";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import InfoIcon from "@mui/icons-material/Info";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

class LstFind extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      SelectedData: [],
      videolist: [],
      searchfromdate: common.formatDate(new Date()),
      valuefromdate: common.formatDate(
        new Date().setDate(new Date().getDate() - 7)
      ),
      valuetodate: common.formatDate(new Date()),
      searchdriver: "",
      isLoading: false,
      error: false,
      checkboxSelection: [],
      selectionModel: [],
      cols: this._getColsBasedOnWidth(),
    };

    this._getTvLsit().then((result) => {});
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateCols);
    this._getTvLsit();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCols);
  }

  updateCols = () => {
    this.setState({ cols: this._getColsBasedOnWidth() });
  };

  _getColsBasedOnWidth = () => {
    const width = window.innerWidth;

    if (width > 1200) {
      return 12; // 큰 화면에서는 4열
    } else if (width > 900) {
      return 10; // 중간 화면에서는 3열
    } else if (width > 600) {
      return 8; // 작은 화면에서는 2열
    } else {
      return 4; // 모바일 등 아주 작은 화면에서는 1열
    }
  };

  _getTvLsit = async () => {
    try {
      this.setState({ isLoading: true, error: false });
      console.log(this.state.valuefromdate);

      const fromdate = common.formatDate(this.state.valuefromdate);
      const todate = common.formatDate(this.state.valuetodate);
      const title = "";
      const brodcast = "";
      const group = "";
      const type = "";
      const wr_7 = "";
      const wr_subject =
        document.getElementsByName("txtSubject")[0].value || "";
      const videolist = await axios.get("/get/getViideoList", {
        params: {
          fromdate: fromdate,
          todate: todate,
          title: title,
          brodcast: brodcast,
          group: group,
          type: type,
          wr_subject: wr_subject,
          wr_7: wr_7,
          sort: "DESC",
        },
      });

      // Ensure each row has a unique 'id'
      const videolistWithId = videolist.data.map((item, index) => ({
        ...item,
        id: item.wr_id || index + 1,
      }));

      this.setState({
        videolist: videolistWithId,
        isLoading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("getTvLsit E:", e);
    }
  };

  _getPalylist = async () => {
    try {
      const selectedRows = this.state.SelectedData;
      const { Rows } = this.state.SelectedData;
      console.log("selectedRows", Rows);
      console.log("playlist", Rows.length);
      let playlist = Rows.map(function (i) {
        return { index: i, url: Rows.wr_link1 };
      });
      console.log("playlist", playlist);
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("getTvLsit E:", e);
    } finally {
    }
  };

  _changeSelectedDriver = (event) => {
    const SelectedDriver_v = event.target.value;
    this.setState({ searchdriver: SelectedDriver_v });
  };

  _onClickvlist = (event) => {
    console.log("_onClickvlist", event);
    alert("Click");
  };

  _chanvouDate = () => {
    console.log(this.state);
  };

  _gotoHome = () => {
    this.props.navigate("/");
  };

  _handleDblClick = (event) => {
    console.log(event);
    const { SelectedData } = this.state;
    if (!SelectedData.id) {
      alert("선택 되지 않았습니다");
    } else {
      console.log("send >> ", SelectedData);
      this.props.navigate("/player", {
        state: {
          id: SelectedData.id,
          data: SelectedData,
        },
      });
    }
  };
  _playerstart = (id, title, url) => {
    try {
      let playlist = [{ index: 1, url: url }];

      console.log("send >> ", playlist);
      this.props.navigate("/player", { state: { playlist } });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("player E:", e);
    }
  };

  render() {
    const isLogin = localStorage.getItem("isLogin");

    if (!isLogin) {
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    }

    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (this.state.error) {
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button onClick={common.reload}>다시 불러오기</button>
        </div>
      );
    }

    const columns = [
      //{ field: "wr_id", headerName: "ID", width: 90 },
      { field: "wr_subject", headerName: "제목", width: 200 },
      //{ field: "wr_4", headerName: "방송국", width: 120 },
      { field: "wr_3", headerName: "방송일", width: 130 },
      //{ field: "wr_5", headerName: "그룹1", width: 150 },
      //{ field: "wr_2", headerName: "그룹2", width: 150 },
    ];

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 300px;
    `;
    const Th = styled.th`
      padding: 8px;
      background-color: #f2f2f2;
    `;
    const Td = styled.td`
      padding: 8px;
      width: 250px;
    `;

    const CenteredContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    console.log("Z", this.state);
    return (
      <div>
        <Head />
        <div className="form">
          <table>
            <tr colspan="3">
              <td colspan="2">
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateRangePicker", "DateRangePicker"]}
                  >
                    <DemoItem>
                      <DateRangePicker
                        defaultValue={[
                          dayjs(this.state.valuefromdate),
                          dayjs(this.state.valuetodate),
                        ]}
                        onChange={(newValue) => {
                          this.setState({
                            searchfromdate: common.formatDate(newValue[0].$d),
                            searchtodate: common.formatDate(newValue[1].$d),
                          });
                          console.log(
                            this.state.valuefromdate,
                            this.state.valuetodate
                          );
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </td>
              <td rowSpan="2">
                <IconButton
                  variant="contained"
                  onClick={() => {
                    this._getTvLsit();
                  }}
                >
                  <SearchRoundedIcon />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td colspan="1">제목</td>
              <td colspan="1">
                <TextField
                  id="txtSubject"
                  name="txtSubject"
                  label="제목을 입력 하세요"
                  variant="outlined"
                  size="small"
                />
              </td>
            </tr>
          </table>
        </div>
        <CenteredContainer>
          <ImageList
            sx={{ width: "auto", height: "auto" }}
            cols={this.state.cols}
          >
            <ImageListItem
              key="Subheader"
              cols={this.state.cols}
            ></ImageListItem>
            {this.state.videolist.map((item) => (
              <ImageListItem key={item.img}>
                <img src={item.wr_10} alt={item.wr_subject} loading="lazy" />
                <ImageListItemBar
                  title={item.wr_subject}
                  subtitle={item.wr_3}
                  onClick={(event) => {
                    this._playerstart(
                      item.wr_id,
                      item.wr_subject,
                      item.wr_link1
                    );
                  }}
                  actionIcon={
                    <IconButton
                      sx={{ color: "action" }}
                      aria-label={`playarrow ${item.wr_id}`}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </CenteredContainer>
        <div width="400px">
          <Table>
            <tbody>
              <tr>
                <CenteredContainer>
                  <td>
                    <Button
                      variant="contained"
                      color="warning"
                      size="medium"
                      onClick={common.movehome}
                    >
                      닫기
                    </Button>
                  </td>
                </CenteredContainer>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withNavigate(LstFind);
