/* src/inc/menu.js */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";
import axios from "axios";
import * as common from "./functions";

// MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import TheaterComedyOutlinedIcon from "@mui/icons-material/TheaterComedyOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";

const Menu = () => {
  const navigate = useNavigate();
  const [countOfPoint, setCountOfPoint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCountOfPoint = async () => {
      try {
        const userid = localStorage.getItem("mb_id");

        const response = await axios.get("/get/countOfPoint", {
          params: {
            userid: userid,
          },
        });
        console.log(response);
        setCountOfPoint(response);
      } catch (e) {
        console.log("Error", e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountOfPoint();
  }, []);

  const perfCount = !countOfPoint ? "0" : countOfPoint.data[0].point;
  const perfExp = !countOfPoint ? "0" : countOfPoint.data[0].exp;
  const isLogin = localStorage.getItem("isLogin");
  const username = localStorage.getItem("mb_name");

  const setPrice = (price) => {
    return price.toLocaleString("ko-KR");
  };

  if (!isLogin)
    return (
      <div className="loading">
        <h1>잘못된 접근입니다.</h1>
      </div>
    );

  return (
    <div>
      <Head />
      <h6>
        {username}님의 포인트는 {setPrice(perfCount)} 입니다
      </h6>
      <br />
      <div className="form">
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstToday");
                  }}
                  size="large"
                  startIcon={<TodayRoundedIcon />}
                >
                  오늘의 방송
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstFind");
                  }}
                  size="large"
                  startIcon={<CalendarMonthRoundedIcon />}
                >
                  일자별
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstMove");
                  }}
                  size="large"
                  startIcon={<MovieCreationOutlinedIcon />}
                >
                  영화
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstDramaGroup");
                  }}
                  size="large"
                  startIcon={<FormatListNumberedIcon />}
                >
                  드라마
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstFDramaGroup");
                  }}
                  size="large"
                  startIcon={<MovieFilterIcon />}
                >
                  미드.외국드라마
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstEntGroup");
                  }}
                  size="large"
                  startIcon={<TheaterComedyOutlinedIcon />}
                >
                  예능
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                  size="large"
                  startIcon={<LogoutIcon />}
                >
                  로그아웃
                </Button>
              </Stack>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default Menu;
