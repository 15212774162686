/* src/inc/lstMove.js */
import React, { Component } from "react";
/* Bootstrap */
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import * as common from "./functions";
import withNavigate from "./withNavigate";
import Head from "./header";
import axios from "axios";
import moment from "moment";
import "moment/locale/et";
import { DataGrid } from "@mui/x-data-grid";
import "@fontsource/roboto/300.css";

// MUI Components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InfoIcon from "@mui/icons-material/Info";
import ReorderTwoToneIcon from "@mui/icons-material/ReorderTwoTone";

class LstDramaGroup extends Component {
  _getColsBasedOnWidth = () => {
    const width = window.innerWidth;

    if (width > 1200) {
      return 12; // 큰 화면에서는 4열
    } else if (width > 900) {
      return 10; // 중간 화면에서는 3열
    } else if (width > 600) {
      return 8; // 작은 화면에서는 2열
    } else {
      return 4; // 모바일 등 아주 작은 화면에서는 1열
    }
  };
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      SelectedData: [],
      videolist: [],
      brodcast: "",
      valuefromdate: new Date(),
      valuetodate: new Date(),
      searchdriver: "",
      isLoading: false,
      error: false,
      cols: this._getColsBasedOnWidth(),
    };

    this._getTvLsit().then((result) => {});
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateCols);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCols);
  }

  updateCols = () => {
    this.setState({ cols: this._getColsBasedOnWidth() });
  };

  _getTvLsit = async () => {
    try {
      this.setState({ isLoading: true, error: false });
      const title = "";
      const brodcast = this.state.brodcast || "";
      const group = "drama";
      const type = "";
      const wr_7 = "";
      const videolist = await axios.get("/get/getVideoGroupList", {
        params: {
          title: title,
          brodcast: brodcast,
          group: group,
          type: type,
          wr_7: wr_7,
        },
      });

      const videolistWithId = await Promise.all(
        videolist.data.map(async (item, index) => {
          const movieInfo = item.tvdataidx
            ? await common.searchTvByTvId(item.tvdataidx)
            : null;

          const posterPath =
            movieInfo && movieInfo.poster_path
              ? movieInfo.poster_path
              : item.wr_10;

          const vote_average = movieInfo && movieInfo.vote_average;
          const movieid = movieInfo && movieInfo.id;
          const last_air_date = movieInfo.last_air_date;

          return {
            ...item,
            id: item.wr_id || index + 1,
            wr_8: movieid,
            as_star_score: vote_average,
            wr_9: posterPath,
            last_air_date: last_air_date,
          };
        })
      );
      const sortedVideos = videolistWithId.sort(
        (a, b) => new Date(b.lastwriteday) - new Date(a.lastwriteday)
      );

      this.setState({
        videolist: sortedVideos,
        isLoading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("getTvLsit E:", e);
    }
  };

  _changeSelectedDriver = (event) => {
    const SelectedDriver_v = event.target.value;
    this.setState({ searchdriver: SelectedDriver_v });
  };

  _onClickvlist = (event) => {
    console.log("_onClickvlist", event);
    alert("Click");
  };

  _chanvouDate = () => {
    console.log(this.state);
  };

  _gotoHome = () => {
    this.props.navigate("/");
  };

  _playerstart = (title, brodcast) => {
    try {
      this.props.navigate("/lstSubPlay", {
        state: {
          title: title,
          brodcast: brodcast,
        },
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("_playerstart E:", e);
    }
  };

  _handleInfoClick = (movieId) => {
    const url = `https://www.themoviedb.org/tv/${movieId}`;
    window.open(url, "_blank");
  };

  render() {
    const isLogin = localStorage.getItem("isLogin");

    if (!isLogin) {
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    }

    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (this.state.error) {
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button onClick={common.reload}>다시 불러오기</button>
        </div>
      );
    }

    const columns = [
      { field: "GroupName", headerName: "제목", width: 200 },
      { field: "brodcast", headerName: "방송국", width: 130 },
    ];

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 300px;
    `;
    const Th = styled.th`
      border: 1px solid black;
      padding: 8px;
    `;
    const Td = styled.td`
      padding: 8px;
      width: 250px;
    `;

    const CenteredContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    console.log("Z", this.state);

    return (
      <div>
        <Head />
        <div className="form">
          <table>
            <tr colspan="3">
              <td>구분</td>
              <td>
                <select
                  class="custom-select"
                  id="inputGroupSelect01"
                  value={this.state.brodcast}
                  onChange={(event) =>
                    this.setState({ brodcast: event.target.value })
                  }
                >
                  <option key="ALL" value="">
                    전체{" "}
                  </option>
                  <option key="KBS2" value="KBS2">
                    KBS2
                  </option>
                  <option key="SBS" value="SBS">
                    SBS
                  </option>
                  <option key="MBC" value="MBC">
                    MBC
                  </option>
                  <option key="CATV" value="CATV">
                    CATV
                  </option>
                </select>{" "}
              </td>
              <td rowSpan="3">
                <IconButton
                  variant="contained"
                  onClick={() => {
                    this._getTvLsit();
                  }}
                >
                  <SearchRoundedIcon />
                </IconButton>
              </td>
            </tr>
          </table>
        </div>

        <div
          style={{
            height: "90%",
            width: "100%",
            fontSize: 5,
            minHeight: "180p",
          }}
        >
          <CenteredContainer>
            <ImageList
              sx={{ width: "auto", height: "auto", minHeight: "180px" }}
              cols={this.state.cols}
            >
              <ImageListItem
                key="Subheader"
                cols={this.state.cols}
              ></ImageListItem>
              {this.state.videolist.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`https://image.tmdb.org/t/p/w300${item.wr_9}`}
                    alt={item.GroupName}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.GroupName}
                    subtitle={
                      "평점: " +
                      (item.as_star_score !== null ? item.as_star_score : "N/A")
                    }
                    sx={{
                      maxHeight: "80px",
                      minHeight: "80px",
                    }}
                    actionIcon={
                      <div>
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.54)",
                          }}
                          aria-label={`info about ${item.wr_id}`}
                          onClick={() => this._handleInfoClick(item.wr_8)}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.54)",
                          }}
                          aria-label={`First name ${item.wr_id}`}
                          onClick={(event) =>
                            this._playerstart(item.GroupName, item.brodcast)
                          }
                        >
                          <ReorderTwoToneIcon />
                        </IconButton>
                      </div>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </CenteredContainer>
        </div>

        <div width="400px">
          <Table>
            <tbody>
              <tr>
                <CenteredContainer>
                  <td>
                    <Button
                      variant="contained"
                      color="warning"
                      size="medium"
                      onClick={common.movehome}
                    >
                      닫기
                    </Button>
                  </td>
                </CenteredContainer>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withNavigate(LstDramaGroup);
