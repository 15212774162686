/* src/inc/regUser.js */
import React, { Component } from "react";
import Head from "./header";

import axios from "axios";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import * as common from "./functions";

class EdtUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userNo: "",
      userName: "",
      userPhone: "",
      userEMail: "",
      userPassword: "",
      userTaxinfo: "",
      userVehicleNo: "",
      userApproved: 0,
      isLoading: true,

      isLoading: true,
      error: null,
    };

    this._getdriverinfo().then((driverinfo) => {
      console.log(driverinfo);
    });
  }
  _udtData = async (e) => {
    const userNo = this.state.userNo.trim();
    const userName = this.state.userName.trim();
    const userPhone = this.state.userPhone.trim();
    const userEMail = this.state.userEMail.trim();
    const userPassword = this.state.userPassword.trim();
    const userTaxinfo = this.state.userTaxinfo.trim();
    const userVehicleNo = this.state.userVehicleNo.trim();
    const userApproved = this.state.userApproved;

    if (userNo === "") {
      return alert("차량 호수 입력해주세요.");
    } else if (userName === "") {
      return alert("기사 성함을 입력해주세요.");
    } else if (userPhone === "") {
      return alert("전화번호 입력해주세요.");
    } else if (userEMail === "") {
      return alert("이메일을 입력해주세요.");
    } else if (userPassword === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const obj = {
      userNo: userNo,
      userName: userName,
      userPhone: userPhone,
      userEMail: userEMail,
      userPassword: userPassword,
      userTaxinfo: userTaxinfo,
      userVehicleNo: userVehicleNo,
      userApproved: userApproved,
    };

    console.log(obj);

    const res = await axios("/udt/driver", {
      method: "POST",
      data: obj,
      headers: new Headers(),
    });

    if (res.data) {
      //console.log(res.data.msg);
      if (res.data) {
        alert("정보를 수정 했습니다.");
        return window.location.reload();
      } else {
        alert("이메일 중복, 차량 호수 확인");
      }
    }
  };
  _getdriverinfo = async (e) => {
    try {
      const userNo = localStorage.getItem("driverno");
      const obj = { userNo: userNo };

      const res = await axios("/get/driverinfo", {
        method: "POST",
        data: obj,
        headers: new Headers(),
      });
      if (res.data) {
        const driverinfo = res.data[0];
        console.log("driverinfo : > ", driverinfo);
        if (driverinfo) {
          this.setState({
            userNo: driverinfo.driverno,
            userName: driverinfo.username,
            userPhone: driverinfo.phoneno,
            userEMail: driverinfo.email,
            userTaxinfo: driverinfo.taxid,
            userVehicleNo: driverinfo.linceplate,
            isLoading: false,
          });
        }
      }
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("getData E:", e);
    }
  };

  _addData = async (e) => {};

  _changeuserNO = function () {
    const userNo_v = document.getElementsByName("userNo")[0].value;

    this.setState({
      userNo: userNo_v,
    });
  };

  _changeuserName = function () {
    const userName_v = document.getElementsByName("userName")[0].value;

    this.setState({
      userName: userName_v,
    });
  };

  _changeuserPhone = function () {
    const userPhone_v = document.getElementsByName("userPhone")[0].value;

    this.setState({
      userPhone: userPhone_v,
    });
  };

  _changeuserEMail = function () {
    const userEMail_v = document.getElementsByName("userEMail")[0].value;

    this.setState({
      userEMail: userEMail_v,
    });
  };

  _changeuserPassword = function () {
    const userPassword_v = document.getElementsByName("userPassword")[0].value;

    this.setState({
      userPassword: userPassword_v,
    });
  };

  _changeuserTaxinfo = function () {
    const userTaxinfo_v = document.getElementsByName("userTaxinfo")[0].value;

    this.setState({
      userTaxinfo: userTaxinfo_v,
    });
  };

  _changeuserVehicleNo = function () {
    const userVehicleNo_v =
      document.getElementsByName("userVehicleNo")[0].value;

    this.setState({
      userVehicleNo: userVehicleNo_v,
    });
  };

  _changeuserApproved = function () {
    const userApproved_v = document.getElementsByName("userApproved")[0].value;

    this.setState({
      userApproved: userApproved_v,
    });
  };

  movehome = function (e) {
    return window.location.replace("/adminmenu");
  };

  render() {
    const isLogin = localStorage.getItem("isLogin");

    if (!isLogin)
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    if (this.state.isLoading)
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    if (this.state.error)
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button>다시 불러오기</button>
        </div>
      );
    const driverinfor = this.state;

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 300px;
    `;
    const Th = styled.th`
      border: 1px solid black;
      padding: 8px;
      background-color: #f2f2f2;
    `;
    const Td = styled.td`
      border: 1px solid black;
      padding: 8px;
      width: 150px;
    `;

    console.log("Z:<>", driverinfor);
    return (
      <div>
        <Head />
        <h2>기사 등록</h2>
        <div className="form">
          <table>
            <caption>*항목은 반드시 입력 하세요</caption>
            <thead>
              <tr>
                <th scope="col" colspan="2" align="center">
                  기사 정보
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <td>기사번호</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userNo"
                    disabled
                    value={driverinfor.userNo}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>기사성함</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userName"
                    value={driverinfor.userName}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Phone No</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userPhone"
                    placeholder="Phone No"
                    value={driverinfor.userPhone}
                    onChange={() => this._changeuserPhone()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>E-Mail</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userEMail"
                    placeholder="E-Mail"
                    value={driverinfor.userEMail}
                    onChange={() => this._changeuserEMail()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Password</td>
                <td>
                  <input
                    class="custom-input"
                    type="password"
                    name="userPassword"
                    placeholder="Password"
                    onChange={() => this._changeuserPassword()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Tax Info</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userTaxinfo"
                    placeholder="SSN or EIN"
                    value={driverinfor.userTaxinfo}
                    onChange={() => this._changeuserTaxinfo()}
                  />
                </td>
              </tr>
              <tr>
                {" "}
                <td>Vehicle No</td>
                <td>
                  <input
                    class="custom-input"
                    type="text"
                    name="userVehicleNo"
                    placeholder="Vehicle No"
                    value={driverinfor.userVehicleNo}
                    onChange={() => this._changeuserVehicleNo()}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <Table>
              <tr>
                <Td>
                  <input
                    className="bbtn"
                    value="저장"
                    onClick={this._udtData}
                  />
                </Td>
                <Td>
                  <input
                    className="ybtn"
                    value="닫기"
                    onClick={common.movehome}
                  />
                </Td>
              </tr>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default EdtUser;
