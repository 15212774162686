import React, { Component } from "react";
/* Bootstrap */
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import * as common from "./functions";
import withNavigate from "./withNavigate";
import Head from "./header";
import axios from "axios";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import "@fontsource/roboto/300.css";

// MUI Components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InfoIcon from "@mui/icons-material/Info";
import PlayCircleOutlineTwoToneIcon from "@mui/icons-material/PlayCircleOutlineTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class LstMove extends Component {
  _getColsBasedOnWidth = () => {
    const width = window.innerWidth;

    if (width > 1200) {
      return 12; // 큰 화면에서는 4열
    } else if (width > 900) {
      return 10; // 중간 화면에서는 3열
    } else if (width > 600) {
      return 8; // 작은 화면에서는 2열
    } else {
      return 4; // 모바일 등 아주 작은 화면에서는 1열
    }
  };
  state = {
    visible: false,
    SelectedData: [],
    videolist: [],
    brodcast: "",
    valuefromdate: new Date(),
    valuetodate: new Date(),
    searchdriver: "",
    videoinfo: [],
    isLoading: false,
    error: false,
    cols: this._getColsBasedOnWidth(),
  };

  _getTvList = async () => {
    try {
      this.setState({ isLoading: true, error: false });
      const { brodcast } = this.state;
      const wr_subject =
        document.getElementsByName("txtSubject")[0].value || "";
      const videolist = await axios.get("/get/getVideolistNoterm", {
        params: {
          title: "",
          brodcast: "MOVIE",
          group: brodcast,
          type: "",
          wr_7: "",
          wr_subject,
          sortfild: "wr_3",
          sort: "DESC",
        },
      });

      const videolistWithId = await Promise.all(
        videolist.data.map(async (item, index) => {
          const vote_average = 0;
          if (!item.wr_12) {
            // const movieInfo = await common.searchMovieByMovieId(item.wr_12);
            // vote_average = movieInfo && movieInfo.vote_average;
          }
          //isNaN(item.wr_12) ??

          //? await common.searchMovieByTitle(
          //item.wr_6.split(":")[0].split("(")[0].trim()
          //     item.wr_6
          //  )
          // :
          //console.log("movieInfo", movieInfo);

          return {
            ...item,
          };
        })
      );

      this.setState({
        videolist: videolistWithId,
        isLoading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.error("getTvList Error:", e);
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateCols);
    this._getTvList();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCols);
  }

  updateCols = () => {
    this.setState({ cols: this._getColsBasedOnWidth() });
  };
  _handleOnSelect = (selectedRowData) => {
    this.setState({ SelectedData: selectedRowData });
    if (selectedRowData) {
      this.props.navigate("/player", {
        state: { id: selectedRowData.wr_id, data: selectedRowData },
      });
    } else {
      alert("선택 되지 않았습니다");
    }
  };

  _playerstart = (id, title, url) => {
    try {
      let playlist = [{ index: 1, url: url }];

      console.log("send >> ", playlist);
      this.props.navigate("/player", { state: { playlist } });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("player E:", e);
    }
  };

  _handleInfoClick = (movieId) => {
    const url = `https://www.themoviedb.org/movie/${movieId}`;
    window.open(url, "_blank");
  };

  render() {
    const { isLoading, error, videolist, brodcast } = this.state;
    const isLogin = localStorage.getItem("isLogin");

    if (!isLogin) {
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    }

    if (isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (error) {
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button onClick={common.reload}>다시 불러오기</button>
        </div>
      );
    }

    const columns = [
      { field: "wr_subject", headerName: "제목", width: 200 },
      { field: "wr_3", headerName: "방송일", width: 130 },
    ];

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 400px;
    `;
    const Td = styled.td`
      padding: 8px;
      width: 250px;
    `;
    const CenteredContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    return (
      <div>
        <Head />
        <div className="form" width="300px">
          <Table>
            <tbody>
              <tr>
                <Td width="50px">구분</Td>
                <Td width="200px">
                  <Box sx={{ minWidth: 200, height: "25%" }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={brodcast}
                        onChange={(event) =>
                          this.setState({ brodcast: event.target.value })
                        }
                        sx={{ maxWidth: 200, maxHeight: 40 }}
                      >
                        <MenuItem value="">전체</MenuItem>
                        <MenuItem value="Korean">한국영화</MenuItem>
                        <MenuItem value="USA">미국영화</MenuItem>
                        <MenuItem value="Asia">아시아</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Td>
                <Td rowSpan="2">
                  <IconButton variant="contained" onClick={this._getTvList}>
                    <SearchRoundedIcon />
                  </IconButton>
                </Td>
              </tr>
              <tr>
                <Td>제목</Td>
                <Td>
                  <TextField
                    id="txtSubject"
                    name="txtSubject"
                    label="제목을 입력 하세요"
                    variant="outlined"
                    size="small"
                  />
                </Td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div style={{ height: "90%", width: "100%" }}>
          <CenteredContainer>
            <ImageList
              sx={{ width: "auto", height: "auto" }}
              cols={this.state.cols}
            >
              <ImageListItem
                key="Subheader"
                cols={this.state.cols}
              ></ImageListItem>
              {this.state.videolist.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.wr_10} alt={item.wr_subject} loading="lazy" />
                  <ImageListItemBar
                    title={item.wr_subject.replace("VII", "").replace(":", "")}
                    subtitle={
                      "평점: " + (item.wr_13 !== null ? item.wr_13 : "N/A")
                    }
                    actionIcon={
                      <div>
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.54)",
                          }}
                          aria-label={`info about ${item.wr_id}`}
                          onClick={() => this._handleInfoClick(item.wr_12)}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: "rgba(255, 255, 255, 0.54)",
                          }}
                          aria-label={`First name ${item.wr_id}`}
                          onClick={() =>
                            this._playerstart(
                              item.wr_id,
                              item.wr_subject,
                              item.wr_link1
                            )
                          }
                        >
                          <PlayCircleOutlineTwoToneIcon />
                        </IconButton>
                      </div>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </CenteredContainer>
        </div>
        <div width="400px">
          <Table>
            <tbody>
              <tr>
                <CenteredContainer>
                  <td>
                    <Button
                      variant="contained"
                      color="warning"
                      size="medium"
                      onClick={common.movehome}
                    >
                      닫기
                    </Button>
                  </td>
                </CenteredContainer>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withNavigate(LstMove);
