import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Hls from "hls.js";

const VideoPlayer = forwardRef(({ url, onEnded, onLoadedMetadata }, ref) => {
  const videoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    play() {
      if (videoRef.current) {
        videoRef.current.play();
      }
    },
    pause() {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    },
  }));

  useEffect(() => {
    const video = videoRef.current;
    let hls;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = url;
    }

    const handleLoadedMetadata = () => {
      if (onLoadedMetadata) {
        onLoadedMetadata();
      }
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("ended", onEnded);

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        video.removeEventListener("ended", onEnded);
      }
      if (hls) {
        hls.destroy();
      }
    };
  }, [url, onEnded, onLoadedMetadata]);

  return <video ref={videoRef} controls style={{ width: "100%" }} />;
});

export default VideoPlayer;
