import React, { useRef, useState, useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
import withLocation from "./withLocation";
import Button from "react-bootstrap/Button";
import Head from "./header";

const Player = (props) => {
  const location = props.location;
  const locationData = location.state;
  const playList = locationData ? locationData.playlist : [];
  const videoPlayerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isUserInteracted && isLoaded && videoPlayerRef.current) {
      setTimeout(() => {
        videoPlayerRef.current.play();
      }, 3000); // 3초 지연
    }
  }, [currentIndex, isUserInteracted, isLoaded]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
    if (videoPlayerRef.current) {
      setIsLoaded(true);
      setTimeout(() => {
        videoPlayerRef.current.play();
      }, 3000); // 3초 지연
    }
  };

  const handleLoadedMetadata = () => {
    setIsLoaded(true);
  };

  const handleEnded = () => {
    console.log(currentIndex);
    if (currentIndex < playList.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setIsLoaded(false); // Reset loaded state for next video
    } else {
      alert("All videos have been played");
    }
    console.log("x", playList);
  };

  return (
    <div>
      <Head />
      {playList.length > 0 ? (
        <>
          <VideoPlayer
            url={playList[currentIndex].url}
            ref={videoPlayerRef}
            onEnded={handleEnded}
            onLoadedMetadata={handleLoadedMetadata}
          />
          {!isUserInteracted && (
            <Button onClick={handleUserInteraction}>Play Video</Button>
          )}
        </>
      ) : (
        <p>No video available</p>
      )}
    </div>
  );
};

export default withLocation(Player);
