import React, { Component, useState, useEffect } from "react";

import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license";

import {
  Home,
  Login,
  Menu,
  RegUser,
  EdtUser,
  AdminMenu,
  LstToday,
  LstMove,
  Player,
  LstFind,
  LstSubPlay,
  LstDramaGroup,
  LstEntGroup,
  LstFDramaGroup,
} from "./inc";

LicenseInfo.setLicenseKey(
  "16f1a6558e792f8e0fbb5181573bf73dTz05NTk0MSxFPTE3NTUwMDgyNjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/Login" Component={Login} />
            <Route path="/Menu" Component={Menu} />
            <Route path="/adminmenu" Component={AdminMenu} />
            <Route path="/lstToday" Component={LstToday} />
            <Route path="/lstFind" Component={LstFind} />
            <Route path="/lstMove" Component={LstMove} />
            <Route path="/lstDramaGroup" Component={LstDramaGroup} />
            <Route path="/lstFDramaGroup" Component={LstFDramaGroup} />
            <Route path="/lstSubPlay" Component={LstSubPlay} />
            <Route path="/lstEntGroup" Component={LstEntGroup} />

            <Route path="/player" Component={Player} />
            <Route path="/regUser" Component={RegUser} />
            <Route path="/edtUser" Component={EdtUser} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
