import React from "react";
import { useLocation } from "react-router-dom";

function withLocation(Component) {
  return React.forwardRef((props, ref) => {
    const location = useLocation();
    return <Component {...props} location={location} ref={ref} />;
  });
}

export default withLocation;
