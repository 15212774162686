/* src/inc/adminmenu.js */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import BusinessIcon from "@mui/icons-material/Business";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RepeatOneOnIcon from "@mui/icons-material/RepeatOneOn";

const AdminMenu = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Head />
      <h2>관리자메뉴 </h2>
      <div className="form">
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/regUser");
                  }}
                  size="large"
                  startIcon={<PersonAddAltIcon />}
                >
                  기사 등록
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          {" "}
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/regCustomers");
                  }}
                  size="large"
                  startIcon={<BusinessIcon />}
                >
                  고객사 등록
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/regAdmVoucher");
                  }}
                  size="large"
                  startIcon={<EditLocationAltIcon />}
                >
                  바우처 등록
                </Button>
              </Stack>
            </div>
          </div>
        </p>
        <p>
          <div class="div-container">
            <div>
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    maxWidth: "350px",
                    maxHeight: "80px",
                    minWidth: "250px",
                    minHeight: "50px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigate("/lstAdmVoucher");
                  }}
                  size="large"
                  startIcon={<RepeatOneOnIcon />}
                >
                  바우처 리스트
                </Button>
              </Stack>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default AdminMenu;
