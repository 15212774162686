import React, { Component } from "react";
import Head from "./header";
import axios from "axios";

// MUI Components
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { Container, Box, Typography } from "@mui/material";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userNo: "",
      userPassword: "",
      login: false,
    };
  }

  _isLogin = async (e) => {
    const username = this.state.userNo.trim();
    const password = this.state.userPassword.trim();
    if (username === "") {
      return alert("아이디를 입력해주세요.");
    } else if (password === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const obj = { username: username, password: password };

    try {
      const res = await axios.post("/post/login", obj, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) {
        console.log("_isLogin >> ", res.data.userinfo);
        const result = res.data.userinfo;
        console.log("result >> ", result);
        if (res.data.suc) {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("mb_id", result.mb_id);
          localStorage.setItem("mb_name", result.mb_name);
          localStorage.setItem("mb_level", result.mb_level);
          localStorage.setItem("mb_point", result.mb_point);
          localStorage.setItem("mb_aslevel", result.mb_aslevel);
          localStorage.setItem("as_exp", result.as_exp);
          localStorage.setItem("as_partner", result.as_partner);

          this.setState({ login: true });
          alert("로그인 되었습니다.");
          return window.location.replace("/menu");
        } else {
          return alert("아이디 및 비밀번호가 일치 하지 않습니다.");
        }
      }
    } catch (error) {
      console.error("Login Error:", error);
      alert("로그인 중 오류가 발생했습니다.");
    }
  };

  _changeID = (e) => {
    this.setState({
      userNo: e.target.value,
    });
  };

  _changePW = (e) => {
    this.setState({
      userPassword: e.target.value,
    });
  };

  render() {
    if (localStorage.getItem("isLogin")) {
      return window.location.replace("/menu");
    }
    return (
      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img src={`/assets/logo192.png`} width="100%" />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            width: "100%",
            maxWidth: 400,
          }}
        >
          <Typography component="h1" variant="h5">
            로그인
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Box sx={{ mb: 2 }}>
              <input
                className="login"
                type="text"
                name="userNo"
                placeholder="아이디"
                onChange={this._changeID}
                style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <input
                className="login"
                type="password"
                name="userPassword"
                placeholder="비밀번호"
                onChange={this._changePW}
                style={{ width: "100%", padding: "10px" }}
              />
            </Box>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                style={{
                  maxWidth: "350px",
                  maxHeight: "80px",
                  minWidth: "250px",
                  minHeight: "50px",
                }}
                variant="outlined"
                onClick={this._isLogin}
                size="large"
                startIcon={<LoginRoundedIcon />}
              >
                로그인
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default Login;
