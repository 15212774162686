import React from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="Home">
      <Head />
      <img src={`/assets/logo192.png`} width="600px" alt="Korean TV Logo" />
      <h2>KOREAN TV</h2>
      <br />
      <h4>본 APP은 회원 전용 입니다.</h4>
      <h4>회원 가입은 www.ikoretv.com에서 해주세요</h4>
      <br />
      <h4>광고 문의는 아래 메일로 문의 해주시기 바랍니다.</h4>
      <h4>Connect: justin.kim@bluescreensolution.com</h4>
      <br />
      <img
        src={`/assets/Account-Login-Button.jpg`}
        width="300px"
        alt="Login Button"
        onClick={() => {
          sessionStorage.adminlogin
            ? navigate("/adminmenu")
            : navigate("/login");
        }}
      />
    </div>
  );
};

export default Home;
