/* src/inc/lstSubPlay.js */
import React, { Component } from "react";
/* Bootstrap */
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import * as common from "./functions";
import withNavigate from "./withNavigate";
import withLocation from "./withLocation";

import Head from "./header";
import axios from "axios";
import moment from "moment";
import "moment/locale/et";
import { DataGrid } from "@mui/x-data-grid";
import "@fontsource/roboto/300.css";

// MUI Components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { sizing } from "@mui/system";

class LstSubPlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      SelectedData: [],
      videolist: [],
      searchdriver: "",
      isLoading: false,
      error: false,
    };

    this.location = this.props;
    this.locationData = this.props.location.state;
    this._getTvLsit().then((result) => {});
  }

  _getTvLsit = async () => {
    try {
      console.log(this.locationData);
      this.setState({ isLoading: true, error: false });
      const title = this.locationData.title;
      const brodcast = this.locationData.brodcast;
      const group = "";
      const type = "";
      const wr_7 = "";
      const wr_subject = "";
      const sortfild = "wr_id";
      const sort = "DESC";
      const videolist = await axios.get("/get/getVideolistNoterm", {
        params: {
          title: title,
          brodcast: brodcast,
          group: group,
          type: type,
          wr_7: wr_7,
          wr_subject: wr_subject,
          sortfild: sortfild,
          sort: sort,
        },
      });

      // Ensure each row has a unique 'id'
      const videolistWithId = videolist.data.map((item, index) => ({
        ...item,
        id: item.wr_id || index + 1,
      }));

      this.setState({
        videolist: videolistWithId,
        isLoading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("getTvLsit E:", e);
    }
  };

  _changeSelectedDriver = (event) => {
    const SelectedDriver_v = event.target.value;
    this.setState({ searchdriver: SelectedDriver_v });
  };

  _onClickvlist = (event) => {
    console.log("_onClickvlist", event);
    alert("Click");
  };

  _chanvouDate = () => {
    console.log(this.state);
  };

  _gotoHome = () => {
    this.props.navigate("/");
  };

  _handleOnSelect = (row) => {
    this.setState({ SelectedData: row });

    console.log("row:", row);
    if (!row) {
      alert("선택 되지 않았습니다");
    } else {
      console.log("send >> ", row);
      this.props.navigate("/player", {
        state: {
          id: row.wr_id,
          data: row,
        },
      });
    }
  };

  _handleDblClick = (event) => {
    console.log(event);
    const { SelectedData } = this.state;
    if (!SelectedData.id) {
      alert("선택 되지 않았습니다");
    } else {
      console.log("send >> ", SelectedData);
      this.props.navigate("/player", {
        state: {
          id: SelectedData.id,
          data: SelectedData,
        },
      });
    }
  };

  _playerstart = (row) => {
    try {
      const selectedRows = this.state.SelectedData;
      const Rows = this.state.SelectedData;
      let i = 0;
      let playlist = Rows.map(function (Row) {
        i = i + 1;
        return { index: i, url: Row.wr_link1 };
      });
      console.log("playlist", playlist);

      if (!selectedRows) {
        alert("선택 되지 않았습니다");
      } else {
        console.log("send >> ", playlist);
        this.props.navigate("/player", {
          state: {
            playlist: playlist,
          },
        });
      }
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("_playerstart E:", e);
    }
  };

  render() {
    const isLogin = localStorage.getItem("isLogin");

    if (!isLogin) {
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    }

    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (this.state.error) {
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button onClick={common.reload}>다시 불러오기</button>
        </div>
      );
    }

    const columns = [
      //{ field: "wr_id", headerName: "ID", width: 90 },
      { field: "wr_subject", headerName: "제목", width: 200 },
      //{ field: "wr_4", headerName: "방송국", width: 120 },
      { field: "wr_3", headerName: "방송일", width: 130 },
      //{ field: "wr_5", headerName: "그룹1", width: 150 },
      //{ field: "wr_2", headerName: "그룹2", width: 150 },
    ];

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 300px;
    `;
    const Th = styled.th`
      padding: 8px;
      background-color: #f2f2f2;
    `;
    const Td = styled.td`
      padding: 8px;
      width: 250px;
    `;

    return (
      <div>
        <Head />
        <div style={{ height: "90%", width: "100%", fontSize: 5 }}>
          <DataGrid
            rows={this.state.videolist}
            columns={columns}
            rowHeight={38}
            sx={{ mb: 1, size: 50 }}
            checkboxSelection
            pageSizeOptions={[10, 20, 30]}
            initialState={{
              ...columns.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            onRowSelectionModelChange={(id) => {
              const selectedIDs = new Set(id);
              const selectedRowData = this.state.videolist.filter((row) =>
                selectedIDs.has(row.wr_id)
              );
              this.setState({ SelectedData: selectedRowData });
              console.log(selectedRowData);
            }}
            //this._handleOnSelect(ids);
          />
        </div>
        <div width="400px">
          <Table>
            <tbody>
              <tr>
                <Td width="200px">
                  <Button
                    variant="contained"
                    color="warning"
                    size="medium"
                    onClick={common.movehome}
                  >
                    닫기
                  </Button>
                </Td>
                <Td>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={this._playerstart}
                  >
                    시청
                  </Button>
                </Td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withLocation(withNavigate(LstSubPlay));
