/* src/inc/lstToday.js */
import React, { Component } from "react";

import DatePicker from "react-datepicker";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import * as common from "./functions";
import withNavigate from "./withNavigate";
import Head from "./header";
import axios from "axios";
import moment from "moment";
import "@fontsource/roboto/300.css";

// MUI COM
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TextField from "@mui/material/TextField";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import InfoIcon from "@mui/icons-material/Info";

class LstToday extends Component {
  _getColsBasedOnWidth = () => {
    const width = window.innerWidth;

    if (width > 1200) {
      return 12; // 큰 화면에서는 4열
    } else if (width > 900) {
      return 10; // 중간 화면에서는 3열
    } else if (width > 600) {
      return 8; // 작은 화면에서는 2열
    } else {
      return 4; // 모바일 등 아주 작은 화면에서는 1열
    }
  };

  state = {
    visible: false,
    SelectedData: [],
    videolist: [],
    searchfromdate: common.formatDate(new Date()),
    searchtodate: common.formatDate(new Date()),
    valuefromdate: new Date(),
    valuetodate: new Date(),
    searchdriver: "",
    isLoading: false,
    error: false,
    cols: this._getColsBasedOnWidth(),
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateCols);
    this._getTvList();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCols);
  }

  updateCols = () => {
    this.setState({ cols: this._getColsBasedOnWidth() });
  };

  _getTvList = async () => {
    try {
      this.setState({ isLoading: true, error: false });
      const wr_subject = "";

      const videolist = await axios.get("/get/getViideoList", {
        params: {
          fromdate: common.formatDate(new Date()),
          todate: common.formatDate(new Date()),
          title: "",
          brodcast: "",
          group: "",
          type: "",
          wr_7: "",
          wr_subject: wr_subject,
          sort: "DESC",
        },
      });

      const videolistWithId = videolist.data.map((item, index) => ({
        ...item,
        id: item.wr_id || index + 1,
      }));

      this.setState({
        videolist: videolistWithId,
        isLoading: false,
        error: false,
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.error("getTvList Error:", e);
    }
  };

  _changeSelectedDriver = (event) => {
    this.setState({ searchdriver: event.target.value });
  };

  _handleOnSelect = (row) => {
    this.setState({ SelectedData: row });

    if (row) {
      this.props.navigate("/player", {
        state: { id: row.wr_id, data: row },
      });
    } else {
      alert("선택 되지 않았습니다");
    }
  };

  _playerstart = (id, title, url) => {
    try {
      let playlist = [{ index: 1, url: url }];

      console.log("send >> ", playlist);
      this.props.navigate("/player", { state: { playlist } });
    } catch (e) {
      this.setState({ isLoading: false, error: true });
      console.log("player E:", e);
    }
  };

  render() {
    const { isLoading, error, videolist } = this.state;

    if (!localStorage.getItem("isLogin")) {
      return (
        <div className="loading">
          <h1>잘못된 접근입니다.</h1>
        </div>
      );
    }

    if (isLoading) {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }

    if (error) {
      return (
        <div className="error">
          <h1>로딩중 에러가 발생했습니다.</h1>
          <button onClick={common.reload}>다시 불러오기</button>
        </div>
      );
    }

    const columns = [
      { field: "wr_10", headerName: "-", width: 200 },
      { field: "wr_subject", headerName: "제목", width: 200 },
      { field: "wr_3", headerName: "방송일", width: 130 },
    ];

    const Table = styled.table`
      width: 100%;
      border-collapse: collapse;
      width: 400px;
    `;
    const Td = styled.td`
      padding: 8px;
      width: 250px;
    `;

    const CenteredContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    function srcset(image: string, size: number, rows = 1, cols = 1) {
      return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
          size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
      };
    }

    return (
      <div>
        <Head />
        <div style={{ height: "90%", width: "100%" }}>
          <CenteredContainer>
            <ImageList
              sx={{ width: "auto", height: "auto" }}
              cols={this.state.cols}
            >
              <ImageListItem
                key="Subheader"
                cols={this.state.cols}
              ></ImageListItem>
              {this.state.videolist.map((item) => (
                <ImageListItem key={item.img}>
                  <img src={item.wr_10} alt={item.wr_subject} loading="lazy" />
                  <ImageListItemBar
                    title={item.wr_subject}
                    subtitle={item.wr_3}
                    onClick={(event) => {
                      this._playerstart(
                        item.wr_id,
                        item.wr_subject,
                        item.wr_link1
                      );
                    }}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`playarrow ${item.wr_7}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </CenteredContainer>
        </div>
        <div width="400px">
          <Table>
            <tbody>
              <tr>
                <CenteredContainer>
                  <td>
                    <Button
                      variant="contained"
                      color="warning"
                      size="medium"
                      onClick={common.movehome}
                    >
                      닫기
                    </Button>
                  </td>
                </CenteredContainer>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withNavigate(LstToday);
