import axios from "axios";

const apiKey = "e3c864d7535066c80c122390fac40789"; // Replace with your actual TMDb API key

export function formatDate(value) {
  const date = new Date(value);
  if (isNaN(date.getTime())) return "-";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function formatDatetime(value) {
  const date = new Date(value);
  if (isNaN(date.getTime())) return "-";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function adminmovehome() {
  return window.location.replace("/adminmenu");
}

export function movehome() {
  return window.location.replace("/menu");
}

export function reload() {
  return window.location.reload();
}

export function historyback() {
  return window.history.back();
}

export function goforward() {
  return window.history.forward();
}

export async function getCompanyName(Companycode) {
  try {
    console.log("_getCompanyName C:", Companycode);
    const companylist = await axios.get("/get/companyname", {
      params: { companycode: Companycode },
    });
    console.log("_getCompanyName R:", companylist);
    return { customernamelist: companylist.data, isLoading: false };
  } catch (e) {
    console.log("Error ", e);
    return { isLoading: false, error: true };
  }
}

export async function getCompanyCode(Companycode) {
  try {
    const companylist = await axios.get("/get/companycode", {
      params: { companycode: Companycode },
    });
    console.log("_getCompanyCode R:", companylist);
    return { customercodelist: companylist.data, isLoading: false };
  } catch (e) {
    console.log("Error-_getCompanyCode ", e);
    return { isLoading: false, error: true };
  }
}

export async function getDriverlist() {
  try {
    const driverno = await axios.get("/get/drivernolist");
    console.log("_getData R:", driverno);
    return { driverno, isLoading: false };
  } catch (e) {
    console.log("getData E:", e);
    return { isLoading: false, error: true };
  }
}

export async function searchMovieByTitle(title) {
  try {
    const searchResponse = await axios.get(
      `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&query=${title}`
    );

    if (searchResponse.data.results.length > 0) {
      const movieId = searchResponse.data.results[0].id;
      const movieResponse = await axios.get(
        `https://api.themoviedb.org/3/movie/${movieId}?api_key=${apiKey}`
      );
      return movieResponse.data;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error("Error fetching movie data");
  }
}
export async function searchMovieByMovieId(movieId) {
  try {
    const movieResponse = await axios.get(
      `https://api.themoviedb.org/3/movie/${movieId}?api_key=${apiKey}`
    );
    return movieResponse.data;
  } catch (error) {
    throw new Error("Error fetching movie data");
  }
}

export async function searchTvByTitle(title, type = "tv") {
  try {
    const endpoint = type === "tv" ? "search/tv" : "search/movie";
    const searchResponse = await axios.get(
      `https://api.themoviedb.org/3/${endpoint}?api_key=${apiKey}&query=${title}`
    );

    if (searchResponse.data.results.length > 0) {
      const id = searchResponse.data.results[0].id;
      const detailsEndpoint = type === "tv" ? "tv" : "movie";
      const detailsResponse = await axios.get(
        `https://api.themoviedb.org/3/${detailsEndpoint}/${id}?api_key=${apiKey}`
      );
      return detailsResponse.data;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error("Error fetching data from TMDb");
  }
}

export async function searchTvByTvId(movieId) {
  try {
    const movieResponse = await axios.get(
      `https://api.themoviedb.org/3/tv/${movieId}?api_key=${apiKey}`
    );
    return movieResponse.data;
  } catch (error) {
    throw new Error("Error fetching movie data");
  }
}
